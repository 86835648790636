import { Container } from "react-bootstrap";
import HospitalRegisterForm from "./HospitalRegisterForm";

const HospitalRegister = () => {
    return (
        <Container className="my-5">
            <h1>병원등록</h1>
            <HospitalRegisterForm />
        </Container>
    );
};

export default HospitalRegister;
