export const TODAY = new Date().toISOString().split("T")[0]; // format된 현재 날짜

export function TOMORROW() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // 오늘 날짜에 하루를 더해 내일 날짜를 계산

    // YYYY-MM-DD 형식으로 날짜 포매팅
    const year = tomorrow.getFullYear(); // 연도
    let month = tomorrow.getMonth() + 1; // 월 (0-11 이므로 1을 더함)
    let day = tomorrow.getDate(); // 일

    // 한 자릿 수 월과 일 앞에 '0'을 추가
    month = month < 10 ? "0" + month : month.toString();
    day = day < 10 ? "0" + day : day.toString();

    // 형식에 맞게 변환
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export const ettRiskGroup = {
    1: { LEVEL: "저", COLOR: "#058EFF" },
    2: { LEVEL: "중", COLOR: "#FC914C" },
    3: { LEVEL: "고", COLOR: "#E23126" },
};
