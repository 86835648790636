import { Form, Card, Row, Col } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import { currentDate } from "store/atoms";
import { ettRiskGroup } from "constants/monitoring";
import { UilAngleRight } from "@iconscout/react-unicons";

const ETTContainer = ({ dropdown, ettInfo }) => {
    const setSearchDate = useSetRecoilState(currentDate);

    const {
        topDateTitle = "-",
        topDate = "-",
        diseaseDate = [],
        botDate = "-",
        riskGroup = "-",
        maxHeartRate = "-",
        restingHeartRate = "-",
        metsValue = "-",
        etcDisease = "-",
    } = ettInfo || {};

    const formattedDiseaseDate = diseaseDate.length === 0 ? "-" : diseaseDate.map((item) => `${item.date} (${item.disease})`).join(", ");

    const ettDetails = [
        { label: topDateTitle, value: topDate },
        { label: "발병질환(기준일)", value: formattedDiseaseDate },
        { label: "운동부하검사일", value: botDate },
        {
            label: "위험군",
            value: riskGroup === "-" ? riskGroup : `${ettRiskGroup[riskGroup].LEVEL}위험군`,
            style: { color: riskGroup === "-" ? "#000000" : ettRiskGroup[riskGroup].COLOR },
        },
        { label: "최대 심박수(Maximal HR)", value: maxHeartRate },
        { label: "안정시 심박수(Resting HR)", value: restingHeartRate },
        { label: "대사당량(METs)", value: metsValue },
        {
            label: "기타 질병",
            value: etcDisease,
            extra: etcDisease === "-" ? null : <span style={{ color: "#B3261E" }}> (있음)</span>,
        },
    ];

    const handleClickETTDate = (e) => {
        const ettDate = e.target.value;
        setSearchDate(ettDate);
    };

    return (
        <Card>
            <Card.Body>
                <Form.Select aria-label="ETT Date Select" onChange={handleClickETTDate} defaultValue={topDate}>
                    {dropdown.map((item) => (
                        <option key={item.ettId} value={item.ettDate}>
                            {item.text}
                        </option>
                    ))}
                </Form.Select>
                <Card.Title className="mt-4">
                    <h4>운동부하검사 기록</h4>
                </Card.Title>
                <div className="mt-3">
                    {ettDetails.map(({ label, value, style = {}, extra }) => (
                        <Row key={label} className="mb-2">
                            <Col md={12} className="text-muted">
                                <h5>
                                    <UilAngleRight className="lh-1 dropdown-indicator-icon mb-1" size={16} />
                                    {label}
                                </h5>
                                <span style={style} className="ms-3">
                                    {value} {extra}
                                </span>
                            </Col>
                            {/* <Col md={8} style={style}>
                            </Col> */}
                        </Row>
                    ))}
                </div>
            </Card.Body>
        </Card>
    );
};

export default ETTContainer;
