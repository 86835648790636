// confirm, warn, error message
const EMPTY_INPUT = "필수 입력값을 입력해주세요.";

const NAME_CHECK = "이름은 2자 이상 입력해주세요.";

const ERROR_ID_EMPTY = "아이디를 입력해주세요.";
const ERROR_ID_CHECK = "아이디를 다시 확인해주세요.";
const CONFIRM_ID = "아이디를 사용하시겠습니까?";
const DULICATED_ID = "아이디를 사용하실 수 없습니다.";

const ERROR_PASSWORD_EMPTY = "비밀번호를 입력해 주세요.";
const ERROR_PASSWORD_MATCH = "비밀번호가 일치하지 않습니다.";

const ERROR_ID_PASSWORD_EMPTY = "아이디/비밀번호를 입력해주세요";

const ERROR_COPORATION_NUMBER = "사업자등록번호는 숫자만 입력해주세요.";

const ERROR_PHONE_NUMBER = "숫자만 입력해주세요.";
const ERROR_PASSWORD_VALID = "영문, 숫자를 조합 6자리 이상 입력해주시기 바랍니다.";

const ALLOWED_IMAGE_FORMATS_MESSAGE = "gif, jpg, jpeg, png 형식의 이미지만 등록이 가능합니다.";

const REGIST_CHECK = "등록하시겠습니까?";
const REGIST_FAIL = "등록에 실패했습니다.";

const MODIFY_SUCCESS = "수정되었습니다.";
const MODIFY_FAIL = "변경사항을 저장할 수 없습니다.";

const INVALID_PHONE_NUMBER_LENGTH = "핸드폰 번호는 중간 3~4자리, 마지막 4자리 숫자로 입력해주세요.";

const INVALID_DATE = "생년월일보다 이전 날짜를 조회할 수 없습니다.";
const INVALID_RANGE = "운동 기간은 최소 1일부터 최대 12주까지 조회 할 수 있습니다.";

export {
    EMPTY_INPUT,
    ALLOWED_IMAGE_FORMATS_MESSAGE,
    ERROR_ID_CHECK,
    ERROR_ID_EMPTY,
    ERROR_PASSWORD_EMPTY,
    ERROR_ID_PASSWORD_EMPTY,
    NAME_CHECK,
    ERROR_PASSWORD_MATCH,
    ERROR_COPORATION_NUMBER,
    ERROR_PHONE_NUMBER,
    ERROR_PASSWORD_VALID,
    REGIST_CHECK,
    REGIST_FAIL,
    MODIFY_SUCCESS,
    MODIFY_FAIL,
    CONFIRM_ID,
    DULICATED_ID,
    INVALID_PHONE_NUMBER_LENGTH,
    INVALID_DATE,
    INVALID_RANGE,
};
