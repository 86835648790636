import { atom } from "recoil";

const currentDate = atom({
    key: "currentDate",
    default: "",
});

const currentDuration = atom({
    key: "currentDuration",
    default: "day",
});

const listKeyState = atom({
    key: "listKeyState",
    default: "",
});

export { currentDate, currentDuration, listKeyState };
