// 커스텀 툴팁 항목 스타일
const TooltipItem = ({ color, text }) => (
    <p className="label" style={{ display: "flex", gap: "4px", alignItems: "center" }}>
        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="6" fill={color} />
        </svg>
        {text}
    </p>
);

// 마우스 오버 시 나타나는 커스텀 그래프 툴팁
const CustomTooltip = ({ label, items }) => (
    <div className="custom-tooltip" style={{ backgroundColor: "#fff", border: "1px solid #ccc", padding: "10px" }}>
        <p style={{ marginBottom: "4px" }}>{label}</p>
        {items.map((item, index) => (
            <TooltipItem key={index} color={item.color} text={item.text} />
        ))}
    </div>
);

export default CustomTooltip;
