export const RPE_VALUE_MAP = {
    // 6: { color: "rgba(214, 249, 251, 0.5)", text: "전혀 힘들지 않다" },
    // 7: { color: "rgba(190, 239, 255, 0.5)", text: "극도로 편하다" },
    // 8: { color: "rgba(167, 233, 255, 0.5)", text: "상당히 편하다" },
    // 9: { color: "rgba(167, 233, 255, 0.5)", text: "상당히 편하다" },
    // 10: { color: "rgba(134, 225, 255, 0.5)", text: "편하다" },
    // 11: { color: "rgba(134, 225, 255, 0.5)", text: "편하다" },
    // 12: { color: "rgba(134, 225, 255, 0.5)", text: "편하다" },
    // 13: { color: "rgba(79, 211, 255, 0.5)", text: "약간 힘들다" },
    // 14: { color: "rgba(79, 211, 255, 0.5)", text: "약간 힘들다" },
    // 15: { color: "rgba(253, 202, 51, 0.5)", text: "힘들다" },
    // 16: { color: "rgba(253, 202, 51, 0.5)", text: "힘들다" },
    // 17: { color: "rgba(255, 191, 1, 0.5)", text: "매우 힘들다" },
    // 18: { color: "rgba(255, 191, 1, 0.5)", text: "매우 힘들다" },
    // 19: { color: "rgba(252, 105, 8, 0.5)", text: "극도로 힘들다" },
    // 20: { color: "rgba(235, 14, 14, 0.5)", text: "최대한 힘들어 더 이상 할 수 없다" },
    6: { color: "rgba(214, 249, 251, 0.5)", text: "매우 편함" },
    7: { color: "rgba(190, 239, 255, 0.5)", text: "약간 편함" },
    8: { color: "rgba(167, 233, 255, 0.5)", text: "상당히 편함" },
    9: { color: "rgba(167, 233, 255, 0.5)", text: "상당히 편함" },
    10: { color: "rgba(134, 225, 255, 0.5)", text: "편함" },
    11: { color: "rgba(134, 225, 255, 0.5)", text: "편함" },
    12: { color: "rgba(134, 225, 255, 0.5)", text: "편함" },
    13: { color: "rgba(79, 211, 255, 0.5)", text: "약간 힘듦" },
    14: { color: "rgba(79, 211, 255, 0.5)", text: "약간 힘듦" },
    15: { color: "rgba(253, 202, 51, 0.5)", text: "힘듦" },
    16: { color: "rgba(253, 202, 51, 0.5)", text: "힘듦" },
    17: { color: "rgba(255, 191, 1, 0.5)", text: "상당히 힘듦" },
    18: { color: "rgba(255, 191, 1, 0.5)", text: "상당히 힘듦" },
    19: { color: "rgba(252, 105, 8, 0.5)", text: "매우 힘듦" },
    20: { color: "rgba(235, 14, 14, 0.5)", text: "한계" },
};
