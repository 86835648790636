import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
    COMMENTS_PLACEHOLDER,
    DEPARTMENT_PLACEHOLDER,
    EMAIL_PLACEHOLDER,
    NAME_PLACEHOLDER,
    NUMBER_ONLY_PLACEHOLDER,
    PASSWORD_PLACEHOLDER,
} from "constants/placeholder";
import { toast } from "sonner";
import useConfirmDialog from "hooks/useConfirmDialog";

const OperatorInfo = ({ operatorDetail, operatorUUID }) => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const showConfirmDialog = useConfirmDialog();

    const [operatorInfo, setOperatorInfo] = useState({
        name: "",
        phone: "",
        userPassword: "",
        eMail: "",
        department: "",
        comments: "",
    });

    useEffect(() => {
        if (operatorDetail?.data?.operator) {
            setOperatorInfo(operatorDetail.data.operator);
        }
    }, [operatorDetail]);

    const handleInput = (field, value) => {
        setOperatorInfo({ ...operatorInfo, [field]: value });

        let newErrors = { ...errors };

        if (field === "name") {
            newErrors.name = validateName(value);
        }
        if (field === "phone") {
            newErrors.phone = validatePhone(value);
        }
        if (field === "userPassword") {
            newErrors.userPassword = validatePassword(value);
        }

        setErrors(newErrors);
    };

    const validateFields = () => {
        const newErrors = {
            name: validateName(operatorInfo.name),
            phone: validatePhone(operatorInfo.phone),
            userPassword: validatePassword(operatorInfo.userPassword),
        };

        setErrors(newErrors);
        return !newErrors.name && !newErrors.phone && !newErrors.userPassword;
    };

    const handleEditOperatorInfo = (e) => {
        e.preventDefault();
        if (validateFields()) {
            showConfirmDialog({ text: "수정하시겠습니까?", icon: "question" }, () => modifyMutate({ ...operatorInfo, operatorUUID }));
        }
    };

    const { mutate: modifyMutate } = useMutation({
        mutationFn: (requestData) => fetchData("/v1/setting/operator/modify", requestData),
        onSuccess: () => {
            navigate("/operator-setting");
        },
        onError: () => {
            toast.error("수정에 실패하였습니다.");
        },
    });

    const validateName = (name) => {
        if (!name.trim()) {
            return "이름을 입력해야 합니다.";
        }
        return "";
    };

    const validatePhone = (phone) => {
        if (!phone.trim()) {
            return "연락처를 입력해야 합니다.";
        } else if (!/^\d{10,11}$/.test(phone)) {
            return "전화번호가 유효하지 않습니다. 10-11자리 숫자를 입력해 주세요.";
        }
        return "";
    };

    const validatePassword = (password) => {
        if (!password.trim()) {
            return "비밀번호를 입력해야 합니다.";
        } else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password)) {
            return "비밀번호는 8자 이상 필요하며, 문자와 숫자를 포함해야 합니다.";
        }
        return "";
    };

    return (
        <Form noValidate>
            <Row className="mb-3 gx-3">
                <Form.Group as={Col} md="6" controlId="formName">
                    <Form.Label>
                        <h5>아이디</h5>
                    </Form.Label>
                    <Form.Control type="text" value={operatorInfo.userId} disabled />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formName">
                    <Form.Label>
                        <h5>이름 *</h5>
                    </Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder={NAME_PLACEHOLDER}
                        isInvalid={!!errors.name}
                        defaultValue={operatorInfo.name}
                        onKeyUp={(e) => handleInput("name", e.target.value)}
                        maxLength={50}
                        autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3 gx-3">
                <Form.Group as={Col} md="6" controlId="formPhone">
                    <Form.Label>
                        <h5>연락처 *</h5>
                    </Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder={NUMBER_ONLY_PLACEHOLDER}
                        isInvalid={!!errors.phone}
                        defaultValue={operatorInfo.phone}
                        onKeyUp={(e) => handleInput("phone", e.target.value)}
                        maxLength={11}
                        autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formPassword">
                    <Form.Label>
                        <h5>비밀번호 *</h5>
                    </Form.Label>
                    <Form.Control
                        required
                        type="password"
                        placeholder={PASSWORD_PLACEHOLDER}
                        isInvalid={!!errors.userPassword}
                        defaultValue={operatorInfo.userPassword}
                        onKeyUp={(e) => handleInput("userPassword", e.target.value)}
                        maxLength={100}
                    />
                    <Form.Control.Feedback type="invalid">{errors.userPassword}</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3 gx-3">
                <Form.Group as={Col} md="6" controlId="formEmail">
                    <Form.Label>
                        <h5>이메일</h5>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={EMAIL_PLACEHOLDER}
                        defaultValue={operatorInfo.eMail}
                        onChange={(e) => setOperatorInfo({ ...operatorInfo, eMail: e.target.value })}
                        maxLength={100}
                        autoComplete="off"
                    />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formDepartment">
                    <Form.Label>
                        <h5>진료과</h5>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={DEPARTMENT_PLACEHOLDER}
                        defaultValue={operatorInfo.department}
                        onChange={(e) => setOperatorInfo({ ...operatorInfo, department: e.target.value })}
                        maxLength={100}
                        autoComplete="off"
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3 gx-3">
                <Form.Group as={Col} md="6" controlId="formComments">
                    <Form.Label>
                        <h5>비고</h5>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={COMMENTS_PLACEHOLDER}
                        defaultValue={operatorInfo.comments}
                        onChange={(e) => setOperatorInfo({ ...operatorInfo, comments: e.target.value })}
                        maxLength={100}
                        autoComplete="off"
                    />
                </Form.Group>
            </Row>
            <div className="d-flex justify-content-end gap-2">
                <Button onClick={handleEditOperatorInfo}>수정하기</Button>
            </div>
        </Form>
    );
};

export default OperatorInfo;
