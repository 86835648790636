import React from "react";
import logo from "assets/logos/landhlabs_logo.jpg";
import classNames from "classnames";
import dtxLogo from "assets/logos/dtx_logo.svg";

const Logo = ({ width = 27, text = true, textClass, className }) => {
    return (
        <div className={classNames(className, "d-flex align-items-center")}>
            <img src={dtxLogo} alt="phoenix" width={width} />
            {text && <p className={classNames(textClass, "logo-text ms-2")}>하루핏 카디오</p>}
        </div>
    );
};

export default Logo;
