import axios from "axios";
import { API_BASE_URL } from "constants/api";

export const instance = axios.create({
    baseURL: API_BASE_URL,
});

//* 요청 인터셉터 추가 - sessionStorage에서 token을 꺼내서 헤더에 담아 요청합니다.
instance.interceptors.request.use(
    (config) => {
        config.headers.hospitalkey = `cnuh`;

        const token = sessionStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

//* 응답 인터셉터 추가
const responsefulfilledInterceptor = (res) => {
    if (200 <= res.status && res.status < 300) {
        return res.data;
    }
    return Promise.reject(res.data);
};

const responseRejectedInterceptor = (error) => {
    return Promise.reject(error);
};

instance.interceptors.response.use(responsefulfilledInterceptor, responseRejectedInterceptor);
