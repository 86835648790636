import { Button, ButtonGroup } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { currentDuration } from "store/atoms";
import DailyWorkoutResult from "./DailyWorkoutResult";
import WeeklyWorkoutResult from "./WeeklyWorkoutResult";

const WorkoutResultContainer = ({ workoutResult, workoutMutate }) => {
    const [duration, setDuration] = useRecoilState(currentDuration);

    const handleClickDuration = (selectedDuration) => {
        if (duration !== selectedDuration) {
            setDuration(selectedDuration);
            workoutMutate(selectedDuration);
        }
    };

    const TAB_LIST = [
        { duration: "day", title: "일별현황" },
        { duration: "weeks03", title: "3주현황" },
        { duration: "weeks06", title: "6주현황" },
        { duration: "weeks12", title: "12주현황" },
    ];

    return (
        workoutResult && (
            <div>
                <ButtonGroup className="mb-3">
                    {TAB_LIST.map((tab, index) => (
                        <Button
                            key={index}
                            variant={duration === tab.duration ? "primary" : "outline-secondary"}
                            onClick={() => handleClickDuration(tab.duration)}
                        >
                            {tab.title}
                        </Button>
                    ))}
                </ButtonGroup>
                {duration === "day" ? <DailyWorkoutResult workoutResult={workoutResult} /> : <WeeklyWorkoutResult workoutResult={workoutResult} />}
            </div>
        )
    );
};

export default WorkoutResultContainer;
