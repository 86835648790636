/**
 * sessionStorage에 값들을 일부만 지우면서 로그아웃 처리(별도로 원하는 경로가 있는경우 )
 *
 * @function logout
 * @param {function} navigate - navigate 넘겨주세요
 * @param {string} redirectPath - 리더렉션할 경로(defalut : "/")
 */
export const logout = (navigate, redirectPath = "/") => {
    // sessionStorage.clear(); -> X 지워버리면 안될것들이 있음
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("patientUUID");
    sessionStorage.removeItem("searchValue");
    sessionStorage.removeItem("searchResult");
    sessionStorage.removeItem("queryPass");
    sessionStorage.removeItem("remainingTime");
    // console.log("로그아웃!");
    navigate(redirectPath, { replace: true });
};

export default logout;
