import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { Button, Form, Row, Col } from "react-bootstrap";
import { ERROR_ID_CHECK, ERROR_ID_EMPTY, ERROR_PASSWORD_VALID, REGIST_CHECK, REGIST_FAIL } from "constants/errorMessage";
import {
    ALPHA_NUMBER_PLACEHOLER,
    COMMENTS_PLACEHOLDER,
    DEPARTMENT_PLACEHOLDER,
    EMAIL_PLACEHOLDER,
    NAME_PLACEHOLDER,
    NUMBER_ONLY_PLACEHOLDER,
} from "constants/placeholder";
import { ID_REGEX } from "constants/regex";
import useCheckDuplicateId from "hooks/useCheckDuplicateId";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import checkIsEmpty from "utils/checkIsEmpty";
import { validateFormData } from "utils/validateFormData";
import { toast } from "sonner";
import useConfirmDialog from "hooks/useConfirmDialog";

const OperatorRegisterForm = () => {
    const navigate = useNavigate();

    const idRef = useRef(null);
    const passwordRef = useRef(null);

    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        userId: "",
        userPassword: "",
        userPasswordCheck: "",
        eMail: "",
        department: "",
        comments: "",
    });
    const [errors, setErrors] = useState({});
    const [isValidId, setIsValidId] = useState(false);
    const showConfirmDialog = useConfirmDialog();

    const checkDuplicateIdMutate = useCheckDuplicateId(formData.userId, setIsValidId, idRef, passwordRef);

    const { mutate: registMutate } = useMutation({
        mutationFn: (requestData) => fetchData("/v1/setting/operator/regist", requestData),
        onSuccess: () => {
            navigate("/operator-setting");
        },
        onError: () => toast.error(REGIST_FAIL),
    });

    const handleIdChange = (e) => {
        setIsValidId(false);
        handleChangeInput(e, "userId");
    };

    const handleChangeInput = (e, label) => setFormData((prev) => ({ ...prev, [label]: e.target.value }));

    const handleCheckDuplicateId = (id) => {
        setIsValidId(false);

        if (!checkIsEmpty(id)) {
            toast.error(ERROR_ID_EMPTY);
            return;
        }

        if (!ID_REGEX.test(id)) {
            toast.error(ERROR_ID_CHECK);
            return;
        }

        checkDuplicateIdMutate(id);
    };

    const handleRegist = (e) => {
        e.preventDefault();

        const { name, phone, userPassword, userPasswordCheck } = formData;
        const validationResult = validateFormData({ name, phone, userPassword, userPasswordCheck }, isValidId, setErrors);

        if (!validationResult) return;

        showConfirmDialog({ text: REGIST_CHECK }, () => {
            registMutate({
                ...formData,
            });
        });
    };

    return (
        <Form onSubmit={handleRegist}>
            <Row className="mb-3">
                <Col>
                    <Form.Group controlId="formName">
                        <Form.Label>
                            <h5>이름 *</h5>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.name}
                            onChange={(e) => handleChangeInput(e, "name")}
                            placeholder={NAME_PLACEHOLDER}
                            isInvalid={!!errors.name}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formPhone">
                        <Form.Label>
                            <h5>연락처 *</h5>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.phone}
                            onChange={(e) => handleChangeInput(e, "phone")}
                            placeholder={NUMBER_ONLY_PLACEHOLDER}
                            isInvalid={!!errors.phone}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group controlId="formUserId" className="mb-3">
                <Form.Label>
                    <h5>아이디 *</h5>
                </Form.Label>
                <Row className="mb-3">
                    <Col md={6}>
                        <Form.Control
                            type="text"
                            value={formData.userId}
                            onChange={handleIdChange}
                            placeholder={ALPHA_NUMBER_PLACEHOLER}
                            ref={idRef}
                            autoComplete="off"
                        />
                    </Col>
                    <Col md={6}>
                        <Button variant="secondary" onClick={() => handleCheckDuplicateId(formData.userId)}>
                            아이디 중복확인
                        </Button>
                    </Col>
                    <Form.Text className="text-muted">아이디 수정은 불가능합니다.</Form.Text>
                </Row>
            </Form.Group>

            <Row className="mb-3">
                <Col>
                    <Form.Group controlId="formPassword">
                        <Form.Label>
                            <h5>비밀번호 *</h5>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            value={formData.userPassword}
                            onChange={(e) => handleChangeInput(e, "userPassword")}
                            placeholder={ALPHA_NUMBER_PLACEHOLER}
                            ref={passwordRef}
                            isInvalid={!!errors.userPassword}
                        />
                        <Form.Control.Feedback type="invalid">{errors.userPassword}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formPasswordCheck">
                        <Form.Label>
                            <h5>비밀번호 확인 *</h5>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            value={formData.userPasswordCheck}
                            onChange={(e) => handleChangeInput(e, "userPasswordCheck")}
                            placeholder={ALPHA_NUMBER_PLACEHOLER}
                            isInvalid={!!errors.userPasswordCheck}
                        />
                        <Form.Control.Feedback type="invalid">{errors.userPasswordCheck}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <Form.Group controlId="formEmail">
                        <Form.Label>
                            <h5>이메일</h5>
                        </Form.Label>
                        <Form.Control
                            type="email"
                            value={formData.eMail}
                            onChange={(e) => handleChangeInput(e, "eMail")}
                            placeholder={EMAIL_PLACEHOLDER}
                            autoComplete="off"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formDepartment">
                        <Form.Label>
                            <h5>진료과</h5>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.department}
                            onChange={(e) => handleChangeInput(e, "department")}
                            placeholder={DEPARTMENT_PLACEHOLDER}
                            autoComplete="off"
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group controlId="formComments" className="mb-3">
                <Form.Label>
                    <h5>비고</h5>
                </Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder={COMMENTS_PLACEHOLDER}
                    value={formData.comments}
                    onChange={(e) => handleChangeInput(e, "comments")}
                    autoComplete="off"
                    rows={3}
                />
            </Form.Group>

            <div className="d-flex justify-content-end">
                <Button variant="secondary" onClick={() => navigate("/operator-setting")}>
                    뒤로가기
                </Button>
                <Button className="ms-2" type="submit">
                    등록하기
                </Button>
            </div>
        </Form>
    );
};

export default OperatorRegisterForm;
