import { Card, Row } from "react-bootstrap";

const WorkoutSummary = ({ dataType, children }) => {
    return (
        <Card className="p-4 mb-4">
            <Card.Header as="h3" className="text-muted">
                {dataType}
            </Card.Header>
            <Card.Body>
                <Row>{children}</Row>
            </Card.Body>
        </Card>
    );
};

export default WorkoutSummary;
