import { DULICATED_ID, ERROR_PASSWORD_MATCH, ERROR_PASSWORD_VALID, ERROR_PHONE_NUMBER, NAME_CHECK } from "constants/errorMessage";
import { NAME_REGEX, NUMBER_ONLY_REGEX, PASSWORD_REGEX } from "constants/regex";
import checkIsEmpty from "./checkIsEmpty";
import { toast } from "sonner";

// 유효성 검사 유틸 함수
export const validateFormData = ({ name, phone, userPassword, userPasswordCheck }, isValidId, setErrors) => {
    let hasError = false;
    const newErrors = {};

    // 빈 값 검사
    if (!(checkIsEmpty(name) && NAME_REGEX.test(name))) {
        newErrors.name = NAME_CHECK;
        hasError = true;
    }

    if (!checkIsEmpty(phone)) {
        newErrors.phone = ERROR_PHONE_NUMBER;
        hasError = true;
    }

    if (!checkIsEmpty(userPassword)) {
        newErrors.userPassword = ERROR_PASSWORD_VALID;
        hasError = true;
    }

    // 비밀번호 확인 검사
    if (userPassword !== userPasswordCheck) {
        newErrors.userPasswordCheck = ERROR_PASSWORD_MATCH;
        hasError = true;
    }

    // 비밀번호 유효성 검사
    if (userPassword && !PASSWORD_REGEX.test(userPassword)) {
        newErrors.userPassword = ERROR_PASSWORD_VALID;
        hasError = true;
    }

    // 연락처 숫자 외 문자 유무 검사
    if (phone && !NUMBER_ONLY_REGEX.test(phone)) {
        newErrors.phone = ERROR_PHONE_NUMBER;
        hasError = true;
    }

    // 아이디 중복 확인
    if (!isValidId) {
        toast.error(DULICATED_ID);
        hasError = true;
    }

    setErrors(newErrors);

    return !hasError;
};
