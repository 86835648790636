import ReactDOM from "react-dom/client";
import Router from "router/Router";

import AppProvider from "providers/AppProvider";
import BreakpointsProvider from "providers/BreakpointsProvider";
import ChatWidgetProvider from "providers/ChatWidgetProvider";
import SettingsPanelProvider from "providers/SettingsPanelProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <AppProvider>
        <SettingsPanelProvider>
            <ChatWidgetProvider>
                <BreakpointsProvider>
                    <Router />
                </BreakpointsProvider>
            </ChatWidgetProvider>
        </SettingsPanelProvider>
    </AppProvider>,
);
