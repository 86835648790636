import FormContainer from "components/Login/FormContainer";
import FeatherIcon from "feather-icons-react";

const Login = ({ layout }) => {
    return (
        <>
            <div className="text-center mb-7">
                <h3 className="text-body-highlight">심장재활 디지털 치료제 관리시스템</h3>
            </div>
            <FormContainer />
            {/* <Row className="flex-between-center mb-7">
                <Col xs="auto">
                    <Form.Check type="checkbox" className="mb-0">
                        <Form.Check.Input type="checkbox" name="remember-me" id="remember-me" defaultChecked />
                        <Form.Check.Label htmlFor="remember-me" className="mb-0">
                            아이디 저장
                        </Form.Check.Label>
                    </Form.Check>
                </Col>
                <Col xs="auto">
                    <Link to={`/pages/authentication/${layout}/forgot-password`} className="fs-9 fw-semibold">
                        비밀번호 찾기
                    </Link>
                </Col>
            </Row>

            <div className="text-center">
                <Link to={`/pages/authentication/${layout}/sign-up`} className="fs-9 fw-bold">
                    회원가입
                </Link>
            </div> */}

            <div className="position-relative">
                <hr className="bg-body-secondary mt-5 mb-4" />
                <div className="divider-content-center">문의처</div>
            </div>
            <p className="user-select-auto mt-2 text-body-quaternary">
                <FeatherIcon icon="phone-call" size={15} />
                <span className="ms-2">062-361-9396</span>
            </p>
            <p className="user-select-auto mt-2 text-body-quaternary">
                <FeatherIcon icon="map-pin" size={15} />
                <span className="ms-1">광주광역시 북구 첨단과기로 313, 에이동 104호</span>
            </p>
            <p className="user-select-auto mt-2 text-body-quaternary align-middle">
                <FeatherIcon icon="info" size={15} />
                <span className="ms-2">버전 HCD-DT-WEB v{process.env.REACT_APP_VERSION}</span>
            </p>
        </>
    );
};

export default Login;
