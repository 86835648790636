export const initialConfig = {
    isNavbarVerticalCollapsed: false,
    openNavbarVertical: false, // for responsive
    theme: "light",
    navbarTopAppearance: "default",
    navbarVerticalAppearance: "default",
    navbarPosition: "vertical",
    navbarTopShape: "default",
    // navbarTopShape: "slim",
    isRTL: false,
    isDark: false,
    isChatWidgetVisible: true,
};
