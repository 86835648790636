import classNames from "classnames";
import React from "react";
import { Form } from "react-bootstrap";

const IndeterminateCheckbox = ({ indeterminate, className, ...rest }) => {
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (typeof indeterminate === "boolean") {
            if (ref.current) {
                ref.current.indeterminate = !rest.checked && indeterminate;
            }
        }
    }, [ref, indeterminate]);

    return (
        <Form.Check type="checkbox" className={classNames(className)}>
            <Form.Check.Input type="checkbox" ref={ref} {...rest} />
        </Form.Check>
    );
};
export default IndeterminateCheckbox;
