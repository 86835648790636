// placeholoder
const ID_PLACEHOLDER = "발급 받은 아이디를 입력해주세요.";
const PASSWORD_PLACEHOLDER = "발급 받은 비밀번호를 입력해주세요.";

const HOSPITAL_NAME_PLACEHOLDER = "병원 전체 이름을 입력해주세요.";
const NAME_PLACEHOLDER = "이름을 입력해주세요.";
const NUMBER_ONLY_PLACEHOLDER = "숫자만 입력해주세요.";
const ALPHA_NUMBER_PLACEHOLER = "영문, 숫자 조합하여 입력해주세요";

const LOGO_PLACEHOLDER = "병원 로고를 등록해주세요";
const EMAIL_PLACEHOLDER = "병원 이메일을 입력해주세요";
const COMMENTS_PLACEHOLDER = "기타사항을 입력해주세요";
const DEPARTMENT_PLACEHOLDER = "기타사항을 입력해주세요";

export {
    ID_PLACEHOLDER,
    EMAIL_PLACEHOLDER,
    HOSPITAL_NAME_PLACEHOLDER,
    DEPARTMENT_PLACEHOLDER,
    NAME_PLACEHOLDER,
    NUMBER_ONLY_PLACEHOLDER,
    ALPHA_NUMBER_PLACEHOLER,
    PASSWORD_PLACEHOLDER,
    LOGO_PLACEHOLDER,
    COMMENTS_PLACEHOLDER,
};
