import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilState } from "recoil";
import { currentDate, listKeyState } from "store/atoms";
import { Col, Table, Pagination, Modal, Button as BootstrapButton, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { ettRiskGroup, TABLE_HEADERS } from "constants/monitoring";
import MonitoringVerificationModal from "./MonitoringVerificationModal";

const MonitoringTable = ({ patientInfo, previousDate, searchResult, isOtherDoctor, isPending }) => {
    console.log("🚀 ~ MonitoringTable ~ searchResult:", searchResult);
    const navigate = useNavigate();
    const location = useLocation();
    const setSearchDate = useSetRecoilState(currentDate);
    const [listKey, setListKey] = useRecoilState(listKeyState);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewedRows, setViewedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const OFFSET = 20;
    const TOTAL_RESULT_LENGTH = searchResult?.length | 0;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let page = params.get("page") || 1;
        setCurrentPage(Number(page));
    }, [location.search]);

    useEffect(() => {
        const token = sessionStorage.getItem("token");
        const userId = jwtDecode(token).uuid;
        const storedViewedRows = JSON.parse(sessionStorage.getItem("listViewHistory")) || [];
        const userViewedRows = storedViewedRows.filter((row) => row.userId === userId);
        setViewedRows(userViewedRows);
    }, []);

    const { mutate } = useMutation({
        mutationFn: (reasonNo) =>
            fetchData("/v1/monitoring/patient/matching", {
                patientUUID: patientInfo.patientUUID,
                reasonNo,
            }),
    });

    const handleClickRow = (date, listKey) => {
        setSearchDate(date);
        setListKey(listKey);
        if (isOtherDoctor) {
            let queryPass = JSON.parse(sessionStorage.getItem("queryPass")) || [];
            const token = sessionStorage.getItem("token");
            const userId = jwtDecode(token).uuid;
            const ptUuId = patientInfo.patientUUID;
            const existingEntry = queryPass.find((entry) => entry.userId === userId && entry.ptUuId === ptUuId);

            if (existingEntry) {
                saveViewHistory();
                navigate(`/monitoring/detail`);
            } else {
                setIsModalOpen(true);
            }
        } else {
            saveViewHistory();
            navigate(`/monitoring/detail`);
        }
    };

    const handleLoadMonitoringData = (reasonNo) => {
        mutate(reasonNo);
        saveViewHistory();
        navigate(`/monitoring/detail`);
    };

    const saveViewHistory = () => {
        let listViewHistory = JSON.parse(sessionStorage.getItem("listViewHistory")) || [];
        const token = sessionStorage.getItem("token");
        const userId = jwtDecode(token).uuid;
        listViewHistory.push({ userId, listKey });
        sessionStorage.setItem("listViewHistory", JSON.stringify(listViewHistory));

        //사유서 요구 환자조회 최초 1회만
        if (isOtherDoctor) {
            let queryPass = JSON.parse(sessionStorage.getItem("queryPass")) || [];
            const ptUuId = patientInfo.patientUUID;
            const existingEntry = queryPass.find((entry) => entry.userId === userId && entry.ptUuId === ptUuId);

            if (!existingEntry) {
                queryPass.push({ userId, ptUuId });
                sessionStorage.setItem("queryPass", JSON.stringify(queryPass));
            }
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(TOTAL_RESULT_LENGTH / OFFSET); i++) {
            pageNumbers.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>,
            );
        }
        return pageNumbers;
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        navigate(`/monitoring?page=${pageNumber}`);
    };

    return (
        <>
            <div className="scrollbar ms-n1 ps-1">
                <Table bordered hover className="phoenix-table fs-9 mb-0 border-top">
                    <thead className="bg-body-highlight">
                        <tr>
                            <th className="text-center text-body-tertiary align-middle" style={{ width: "6%", minWidth: "30px" }}>
                                NO
                            </th>
                            <th className="text-center text-body-tertiary align-middle" style={{ width: "10%", minWidth: "100px" }}>
                                운동일자
                            </th>
                            <th
                                className="text-center text-body-tertiary align-middle"
                                style={{ width: "15%", minWidth: "150px", background: "#A3E5FC" }}
                            >
                                <div>운동부하검사</div>
                                <small className="text-muted">위험군, 심박수(최소/대), 대사당량</small>
                            </th>
                            <th
                                className="text-center text-body-tertiary align-middle"
                                style={{ width: "15%", minWidth: "70px", background: "#A3E5FC" }}
                            >
                                <div>6분걷기</div>
                                <small className="text-muted">걸은 거리, 대사당량</small>
                            </th>
                            <th
                                className="text-center text-body-tertiary align-middle"
                                style={{ width: "8%", minWidth: "70px", background: "#A3E5FC" }}
                            >
                                <div>30초STS</div>
                                <small className="text-muted">횟수</small>
                            </th>
                            <th
                                className="text-center text-body-tertiary align-middle"
                                style={{ width: "8%", minWidth: "90px", background: "#FAE196" }}
                            >
                                <div>스트레칭</div>
                                <small className="text-muted">운동시간</small>
                            </th>
                            <th
                                className="text-center text-body-tertiary align-middle"
                                style={{ width: "15%", minWidth: "180px", background: "#FAE196" }}
                            >
                                <div>걷기</div>
                                <small className="text-muted">목표심박수(최소/대), 운동시간, 걸은 거리</small>
                            </th>
                            <th
                                className="text-center text-body-tertiary align-middle"
                                style={{ width: "10%", minWidth: "80px", background: "#FAE196" }}
                            >
                                <div>스쿼트</div>
                                <small className="text-muted">세트/횟수</small>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!searchResult || searchResult.length <= 0 ? (
                            <tr>
                                <td colSpan="8" className="text-center">
                                    {isPending ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : (
                                        "조회된 데이터가 없습니다."
                                    )}
                                </td>
                            </tr>
                        ) : (
                            searchResult.slice((currentPage - 1) * OFFSET, currentPage * OFFSET).map((item, index) => {
                                const isViewed = viewedRows.some((row) => row.listKey === item.listKey);
                                return (
                                    <tr
                                        key={index}
                                        onClick={() => handleClickRow(item.date, item.listKey)}
                                        style={{ backgroundColor: isViewed ? "lightgrey" : null }}
                                        className={"cursor-pointer " + (isViewed ? "bg-gray-100" : "")}
                                    >
                                        <td className="text-center">{item.no}</td>
                                        <td className="text-center">{item.date.replaceAll("-", ".")}</td>
                                        <td className="text-center">
                                            {item.ETT.riskGroup === null && item.ETT.heartRate === null && item.ETT.METs === null ? (
                                                "-"
                                            ) : (
                                                <>
                                                    <span
                                                        className="fs-10 ms-auto badge-phoenix badge me-1 text-secondary"
                                                        style={{ background: ettRiskGroup[item.ETT.riskGroup].COLOR }}
                                                    >
                                                        {ettRiskGroup[item.ETT.riskGroup].LEVEL}
                                                    </span>
                                                    {item.ETT.heartRate === null ? "-, " : item.ETT.heartRate + ", "}
                                                    {item.ETT.METs === null ? "-" : item.ETT.METs}
                                                </>
                                            )}
                                        </td>
                                        <td className="text-center">{item.evaluation.walking6Min === null ? "-" : item.evaluation.walking6Min}</td>
                                        <td className="text-center">{item.evaluation.sts30Sec === null ? "-" : item.evaluation.sts30Sec}</td>
                                        <td className="text-center">{item.workout.stretching === null ? "-" : item.workout.stretching}</td>
                                        <td className="text-center">
                                            <span style={{ color: "green" }}>{`${item.workout.walking.heartRate}, `}</span>
                                            {item.workout.walking.runningTime}, {item.workout.walking.distance}
                                        </td>
                                        <td className="text-center">{item.workout.squat}</td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
            </div>
            <div justifyContent="center" className="d-flex justify-content-center p-2 mb-2">
                <div className="p-2 ">
                    <Pagination>{renderPageNumbers()}</Pagination>
                </div>
            </div>
            <MonitoringVerificationModal
                isModalOpen={isModalOpen}
                handleCloseModal={() => setIsModalOpen(false)}
                handleLoadMonitoringData={handleLoadMonitoringData}
            />
        </>
    );
};

export default MonitoringTable;
