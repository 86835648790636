import React, { useRef, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const UserSetting = () => {
    const nameRef = useRef(null);
    const phoneRef = useRef(null);
    const token = sessionStorage.getItem("token");
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            const { role } = jwtDecode(token);
            if (role !== 0) {
                alert("권한이 없습니다.");
                navigate(-1);
            }
        }
    }, [token, navigate]);

    const handleDeleteUserData = async () => {
        const data = {
            name: nameRef.current.value,
            phone: phoneRef.current.value,
        };

        try {
            const result = await axios.delete("https://dapi.lnhlabs.com/web_api/v1/auth/management/delete/user", {
                headers: { "Content-Type": "application/json" },
                data,
            });

            if (result.status === 204) {
                alert(`${data.name} 의 이름을 가진 환자의 정보가 삭제되었습니다.`);
            } else if (result.status === 200 && result.data.msg === "Item not found") {
                alert(`데이터 삭제에 실패했습니다. 환자 정보를 다시 확인해주세요.`);
            } else {
                alert("서버 오류 입니다. 다시 시도하거나 관리자에게 문의해주세요.");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteUserHistoryData = async (type) => {
        const data = {
            name: nameRef.current.value,
            phone: phoneRef.current.value,
            type: type,
        };

        try {
            const result = await axios.delete("https://dapi.lnhlabs.com/web_api/v1/auth/management/delete/history", {
                headers: { "Content-Type": "application/json" },
                data,
            });

            if (result.status === 200 && result.data.msg === "Item not found") {
                alert(`${data.name} 의 이름을 가진 환자의 데이터를 찾을 수 없습니다. 환자 정보를 다시 확인해주세요.`);
            } else if (result.status === 200) {
                let deleteData = "";
                for (let key in result.data.msg.result) {
                    if (result.data.msg.result[key]) {
                        deleteData += `${key} `;
                    }
                }
                alert(`${data.name} 의 이름을 가진 ${deleteData} 기록 정보가 삭제되었습니다.`);

                let notDeleteData = "";
                for (let key in result.data.msg.result) {
                    if (!result.data.msg.result[key]) {
                        notDeleteData += `${key} `;
                    }
                }
                alert(`${data.name} 의 이름을 가진 ${notDeleteData} 기록 정보가 삭제되지 않았습니다.(데이터 없음)`);
            } else {
                alert("실패했습니다. 다시 시도하거나 관리자에게 문의해주세요.");
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Container className="py-4">
            <h1>QA용 환자 데이터 관리 (임시 페이지)</h1>
            <Form>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>이름</Form.Label>
                        <Form.Control type="text" placeholder="이름을 입력하세요" ref={nameRef} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>전화번호</Form.Label>
                        <Form.Control type="text" placeholder="전화번호를 입력하세요(숫자만 입력)" ref={phoneRef} />
                    </Col>
                </Row>
                <h2>환자 정보 삭제</h2>
                <Button variant="danger" onClick={handleDeleteUserData}>
                    환자 정보 삭제
                </Button>
                <h2 className="mt-4">환자 평가/운동 정보 삭제</h2>
                <Button variant="danger" className="m-2" onClick={() => handleDeleteUserHistoryData("ALL")}>
                    전체 기록 정보 삭제
                </Button>
                <Button variant="danger" className="m-2" onClick={() => handleDeleteUserHistoryData("ETT")}>
                    ETT 기록 정보 삭제
                </Button>
                <Button variant="danger" className="m-2" onClick={() => handleDeleteUserHistoryData("EVALUATION")}>
                    평가 기록 정보 삭제
                </Button>
                <Button variant="danger" className="m-2" onClick={() => handleDeleteUserHistoryData("WORKOUT")}>
                    운동 기록 정보 삭제
                </Button>
            </Form>
        </Container>
    );
};

export default UserSetting;
