import { Card, Row } from "react-bootstrap";

const WorkoutSummary = ({ hTit, children }) => {
    return (
        <>
            <Card.Header as="h3" className="text-muted">
                {hTit}
            </Card.Header>
            <Card.Body>
                <Row>{children}</Row>
            </Card.Body>
        </>
    );
};

export default WorkoutSummary;
