import MonitoringDetailContents from "components/MonitoringDetail/MonitoringDetailContents";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import { useNavigate, useNavigationType } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentDate } from "store/atoms";

const MonitoringDetail = () => {
    const navigate = useNavigate();
    const searchDate = useRecoilValue(currentDate);
    const navigationType = useNavigationType();

    useEffect(() => {
        if (!searchDate) navigate("/monitoring");
    }, [searchDate, navigate]);

    useEffect(() => {
        if (navigationType === "POP") {
            navigate("/monitoring", { replace: true });
        }
    }, []);

    return (
        <>
            <div className="pb-5">
                <Row className="g-4">
                    <div className="mb-2">
                        <h2>환자 모니터링</h2>
                    </div>
                    <hr className="bg-body-secondary mb-3" />
                </Row>
                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            <div className="p-4">{searchDate && <MonitoringDetailContents searchDate={searchDate} />}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MonitoringDetail;
