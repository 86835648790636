import { Button as BootstrapButton } from "react-bootstrap";

/**
 * 버튼 컴포넌트
 *
 * @component
 * @param {Object} props - 컴포넌트에 전달되는 속성들
 * @param {string} [props.type="button"] - 버튼의 타입 (기본값: "button")
 * @param {string} props.buttonText - 버튼에 표시될 텍스트
 * @param {string} [props.color="black"] - 버튼의 배경 색상 (기본값: "black")
 * @param {Function} props.handleClickButton - 버튼 클릭 시 실행될 함수
 * @param {Object} props.buttonProps - 그 외 추가 버튼 속성들
 * @returns {JSX.Element} - 버튼 컴포넌트
 */
const Button = ({ type = "button", buttonText, color = "black", handleClickButton, ...buttonProps }) => {
    // Bootstrap 색상 맵핑
    const bootstrapVariant = color === "white" ? "light" : "dark";

    return (
        <BootstrapButton
            onClick={handleClickButton}
            type={type}
            variant={bootstrapVariant}
            {...buttonProps}
            style={{
                width: "100%",
                padding: "14px 0",
                textAlign: "center",
                fontSize: "1.6rem",
                fontWeight: 700, // 비율은 Bootstrap 기본값으로 매칭
            }}
        >
            {buttonText}
        </BootstrapButton>
    );
};

export default Button;
