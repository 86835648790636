import SummaryTextbox from "components/MonitoringDetail/SummaryTextbox";
import WorkoutSummary from "components/WorkoutResult/WorkoutSummary";
import { RPE_VALUE_MAP } from "constants/rpeValueMap";
import { Table } from "react-bootstrap";
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const Walking6Min = ({ walking6MinData }) => {
    if (!walking6MinData || !walking6MinData.summary || !walking6MinData.graph || !walking6MinData.list) {
        return <>평가기록이 없습니다.</>;
    }

    const { summary, graph, list } = walking6MinData;
    const { cycle, heartRate, distance, metsValue } = summary;

    return (
        <div className="mt-5">
            <h1 className="mb-2">6분 걷기</h1>
            <WorkoutSummary dataType="일일 평가 통계">
                <div className="px-3 mb-5">
                    <div className="justify-content-between row">
                        <SummaryTextbox value={`${cycle} 회`} description="평가횟수" col={3} />
                        <SummaryTextbox value={distance} description="측정거리(m)" startBorder={true} col={3} />
                        <SummaryTextbox value={heartRate} description="최소/대 심박수(bpm)" startBorder={true} col={3} />
                        <SummaryTextbox value={metsValue} description="대사당량(METs)" startBorder={true} col={3} />
                    </div>
                </div>

                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart data={graph}>
                        <defs>
                            <linearGradient id="gradientMax" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="rgba(190,29,149,1)" stopOpacity={1} />
                                <stop offset="100%" stopColor="rgba(190,29,149,0)" stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="gradientMin" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="rgba(53,57,99,1)" stopOpacity={1} />
                                <stop offset="100%" stopColor="rgba(53,57,99,0)" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="index" stroke="dotted" />
                        <YAxis stroke="none" />
                        <Tooltip />
                        <Legend colors={["#BE1D95", "#353963"]} verticalAlign="top" align="right" />
                        <Area type="monotone" dataKey="distance" stroke="#BE1D95" strokeWidth={2} fill="url(#gradientMax)" name="측정거리" />
                        <Area type="monotone" dataKey="metsValue" stroke="#353963" strokeWidth={2} fill="url(#gradientMin)" name="대사당량(METs)" />
                    </AreaChart>
                </ResponsiveContainer>

                <div>
                    <h4 className="mt-4 mb-3">일일 평가 기록</h4>
                    <div className="scrollbar ms-n1 ps-1">
                        <Table bordered hover size="sm" className="phoenix-table fs-9 mb-0 border-top">
                            <thead className="bg-body-highlight">
                                <tr>
                                    <th className="text-end text-body-tertiary pe-2" style={{ width: "6%", minWidth: "30px" }}>
                                        회차
                                    </th>
                                    <th className="text-end px-3 text-body-tertiary" style={{ width: "15%", minWidth: "100px" }}>
                                        <div className="d-inline-flex flex-center gap-2">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                className="svg-inline--fa fa-square fs-11 text-secondary"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{ width: "10px" }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
                                                />
                                            </svg>
                                            측정거리(m)
                                        </div>
                                    </th>
                                    <th className="text-end px-3 text-body-tertiary" style={{ width: "15%", minWidth: "100px" }}>
                                        <div className="d-inline-flex flex-center gap-2">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                className="svg-inline--fa fa-square fs-11 text-primary"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{ width: "10px" }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
                                                />
                                            </svg>
                                            대사당량(METs)
                                        </div>
                                    </th>
                                    <th className="text-end px-3 text-body-tertiary" style={{ width: "15%", minWidth: "125px" }}>
                                        <div className="d-inline-flex flex-center gap-2">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                className="svg-inline--fa fa-square fs-11 text-primary-light"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{ width: "10px" }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
                                                />
                                            </svg>
                                            최소/대 심박수(bpm)
                                        </div>
                                    </th>
                                    <th className="text-end text-body-tertiary  pe-3" style={{ width: "15%", minWidth: "90px" }}>
                                        <div className="d-inline-flex flex-center gap-2">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                className="svg-inline--fa fa-square fs-11 text-success"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{ width: "10px" }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
                                                />
                                            </svg>
                                            휴식시간(시간/회)
                                        </div>
                                    </th>
                                    <th className="text-end text-body-tertiary  pe-3" style={{ width: "15%", minWidth: "100px" }}>
                                        <div className="d-inline-flex flex-center gap-2">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                className="svg-inline--fa fa-square fs-11 text-warning"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{ width: "10px" }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
                                                />
                                            </svg>
                                            운동자각도
                                        </div>
                                    </th>
                                    <th className="text-end text-body-tertiary  pe-3" style={{ width: "15%", minWidth: "95px" }}>
                                        <div className="d-inline-flex flex-center gap-2">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                className="svg-inline--fa fa-square fs-11 text-danger"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{ width: "10px" }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
                                                />
                                            </svg>
                                            안정시간(시간/회)
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.length > 0 ? (
                                    list.map(({ index, distance, metsValue, heartRate, restingSelf, restingDanger, rpeValue }) => (
                                        <tr key={index}>
                                            <td className="text-end pe-3 white-space-nowrap ps-2">{index}</td>
                                            <td className="text-end fw-semibold text-body py-2 px-3">{distance}</td>
                                            <td className="text-end fw-semibold text-body py-2 px-3">{metsValue}</td>
                                            <td className="text-end fw-semibold text-body py-2 px-3">{heartRate}</td>
                                            <td className="text-end fw-semibold text-body py-2 pe-3">{restingSelf}</td>
                                            <td className="text-end fw-semibold text-body py-2 pe-3">
                                                <span
                                                    className="fs-10 ms-auto badge-phoenix badge me-1 text-secondary"
                                                    style={{ background: RPE_VALUE_MAP[rpeValue].color }}
                                                >
                                                    {rpeValue}
                                                </span>
                                                {RPE_VALUE_MAP[rpeValue].text}
                                            </td>
                                            <td className="text-end fw-semibold text-body py-2 pe-3">{restingDanger}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="text-center fw-semibold text-body py-2 px-3" colSpan={7}>
                                            <h4>데이터가 없습니다.</h4>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </WorkoutSummary>
        </div>
    );
};

export default Walking6Min;
