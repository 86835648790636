import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppContext } from "providers/AppProvider";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/ko";

dayjs.locale("ko");
dayjs.extend(utc);
dayjs.extend(timezone);

const MuiDatePicker = ({ maxDate, onChange, value, labelProp, disablProp = false, readOnlyProp = false }) => {
    // 테마 정보를 Context로부터 직접 받음
    const {
        config: { theme },
    } = useAppContext();

    let textFieldStyle;

    //다크 테마 스타일
    const textFieldDarkStyles =
        theme === "dark"
            ? {
                  border: "solid #373e53",
                  color: "#9fa6bc",
                  backgroundColor: "#141824",
              }
            : "";
    const deactivTextFieldDarkStyles =
        theme === "dark"
            ? {
                  border: "solid #373e53",
                  color: "#9fa6bc",
                  backgroundColor: "#d9d9d98f",
              }
            : {
                  color: "#9fa6bc",
              };

    if (disablProp || readOnlyProp) {
        textFieldStyle = deactivTextFieldDarkStyles;
    } else {
        textFieldStyle = textFieldDarkStyles;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
            <DatePicker
                label={labelProp}
                value={dayjs(value)}
                format="YYYY-MM-DD"
                readOnly={readOnlyProp}
                disabled={disablProp}
                onChange={(newValue) => {
                    if (newValue && newValue.isValid()) {
                        const koreaFormattedDate = newValue.tz("Asia/Seoul").format("YYYY-MM-DD");
                        onChange(koreaFormattedDate);
                    } else {
                        onChange(""); // 유효하지 않은 경우 적절한 기본값 설정
                    }
                }}
                {...(maxDate && { maxDate: dayjs(maxDate) })}
                slotProps={{
                    textField: { variant: "outlined" },
                }}
                sx={{
                    width: "100%",
                    "& .MuiInputLabel-root.Mui-focused": { color: "#979797" },
                    "& .MuiOutlinedInput-root": {
                        "&:hover > fieldset": { borderColor: "#C7C8CD" },
                        height: "37px",
                        borderRadius: "6px",
                        background: "white",
                    },
                    "& .MuiInputBase-root": textFieldStyle,
                    "& .MuiFormLabel-root": {
                        color: "#bdbdbd",
                    },
                }}
            />
        </LocalizationProvider>
    );
};

export default MuiDatePicker;
