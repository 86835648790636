import MuiDatePicker from "components/base/MuiDatePicker";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { currentDate } from "store/atoms";
import Sts30Sec from "./Sts30Sec";
import Walking6Min from "./Walking6Min";

const EvaluationResultContainer = ({ evaluationResult }) => {
    console.log("🚀 ~ EvaluationResultContainer ~ evaluationResult:", evaluationResult);
    const [searchDate, setSearchDate] = useRecoilState(currentDate);
    const navigate = useNavigate();

    const handleClickDate = (e) => {
        setSearchDate(e.target.value);
    };

    return (
        evaluationResult && (
            <>
                <div className="w-50">
                    <MuiDatePicker
                        onChange={(value) => {
                            handleClickDate(value);
                        }}
                        value={searchDate}
                    />
                </div>
                <Row>
                    <Col md={12}>
                        <Walking6Min walking6MinData={evaluationResult.data?.walking6Min} />
                    </Col>
                    <Col md={12}>
                        <Sts30Sec sts30SecData={evaluationResult.data?.sts30Sec} />
                    </Col>
                </Row>
                <Button className="w-100" variant="primary" onClick={() => navigate("/monitoring", { replace: true })}>
                    목록
                </Button>
            </>
        )
    );
};

export default EvaluationResultContainer;
