import { Card, Container, Row, Col } from "react-bootstrap";

const PatientInfoBox = ({ patientInfo }) => {
    const { name, birthGender, heightWeight, phone } = patientInfo;
    return (
        <Card className="p-2 mb-4">
            <Card.Header as="h2">{name}</Card.Header>
            <Card.Body>
                <Container>
                    <Row>
                        <Col>{birthGender}</Col>
                    </Row>
                    <Row>
                        <Col>{heightWeight}</Col>
                    </Row>
                    <Row>
                        <Col>{phone}</Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
};

export default PatientInfoBox;
