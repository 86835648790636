import Button from "components/common/Button";
import { useState } from "react";
import { decryptData, encryptData } from "utils/cryptData";

const CryptTest = () => {
    const [isEncrypt, setIsEncrypt] = useState(false);
    const [value, setValue] = useState("복호화 된 값입니다.");

    const handleButtonClick = () => {
        const result = isEncrypt ? decryptData(value) : encryptData(value);
        setValue(result);
        setIsEncrypt((prev) => !prev);
    };

    return (
        <div>
            <p>{value}</p>
            <Button buttonText="암호화/복호화" handleClickButton={handleButtonClick} />
        </div>
    );
};

export default CryptTest;
