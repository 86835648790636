// 커스텀 그래프 범례
const CustomLegend = ({ payload, colors }) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "8px",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingBottom: "20px",
            }}
        >
            {payload.map((entry, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        gap: "4px",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6" cy="6" r="6" fill={colors[index]} />
                    </svg>
                    <span key={`item-${index}`}>{entry.value}</span>
                </div>
            ))}
        </div>
    );
};

export default CustomLegend;
