import MuiDatePicker from "components/base/MuiDatePicker";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { currentDate } from "store/atoms";
import DailySquat from "./DailySquat";
import DailyStretching from "./DailyStretching";
import DailySummary from "./DailySummary";
import DailyWalking from "./DailyWalking";

const DailyWorkoutResult = ({ workoutResult }) => {
    const [searchDate, setSearchDate] = useRecoilState(currentDate);
    const navigate = useNavigate();

    const handleClickDate = (value) => {
        setSearchDate(value);
    };

    return (
        <>
            {/* <Form.Control type="date" value={searchDate} onChange={handleClickDate} style={{ width: "312px", marginBottom: "20px" }} /> */}
            <div className="w-50">
                <MuiDatePicker
                    onChange={(value) => {
                        handleClickDate(value);
                    }}
                    value={searchDate}
                />
            </div>
            <Row>
                <Col md={12}>
                    <DailySummary summaryData={workoutResult.summary} />
                </Col>
                <Col md={12}>
                    <DailyStretching stretchingData={workoutResult.stretching} />
                </Col>
                <Col md={12}>
                    <DailyWalking walkingData={workoutResult.walking} />
                </Col>
                <Col md={12}>
                    <DailySquat squatData={workoutResult.squat} />
                </Col>
            </Row>
            <Button className="w-100" variant="primary" onClick={() => navigate("/monitoring", { replace: true })}>
                목록
            </Button>
        </>
    );
};

export default DailyWorkoutResult;
