import CryptoJS from "crypto-js";

// AES-256 키와 IV 설정
const key = CryptoJS.enc.Utf8.parse("12345678901234567890123456789012"); // 32바이트 키
const iv = CryptoJS.enc.Utf8.parse("1234567890123456"); // 16바이트 IV

// AES-256 암호화 함수
export function encryptData(data) {
    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
    return encrypted.toString(); // 암호화된 문자열을 반환
}

// AES-256 복호화 함수
export function decryptData(encryptedData) {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8); // 복호화된 문자열을 반환
}
