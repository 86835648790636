import { jwtDecode } from "jwt-decode";

//로그인 사용자 권한 레벨 조회
const getUserRoleLevel = () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
        return null;
    }

    try {
        const { role } = jwtDecode(token);
        return role;
    } catch (error) {
        console.error("getUserRoleLevel : Token decoding failed:", error);
        return null;
    }
};

export default getUserRoleLevel;
