import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useConfirmDialog from "hooks/useConfirmDialog";

const StaffTable = ({ operatorDetail, operatorUUID, operatorDetailMutate }) => {
    const navigate = useNavigate();
    const showConfirmDialog = useConfirmDialog();

    const { mutate: deleteMutate } = useMutation({
        mutationFn: (requestData) => fetchData("/v1/setting/operator/staff/delete", requestData),
        onSuccess: () => {
            operatorDetailMutate({ operatorUUID });
        },
    });

    const handleDelete = (e, UUID) => {
        e.stopPropagation();

        // 사용자에게 삭제 확인 요청
        // if (window.confirm("정말로 삭제하시겠습니까?")) {
        //     deleteMutate({ operatorUUID, staffUUID: UUID });
        // }
        //options = {}, onConfirm, onCancel
        showConfirmDialog({ text: "정말로 삭제하시겠습니까?", icon: "question" }, () => deleteMutate({ operatorUUID, staffUUID: UUID }));
    };

    return (
        // <div className="d-flex flex-column gap-4 align-items-end mt-5 w-100">
        <div className="scrollbar ms-n1 ps-1 mt-5">
            <Table bordered hover size="sm" className="phoenix-table fs-9 mb-0 border-top">
                <thead className="bg-body-highlight">
                    <tr>
                        <th className=" text-center text-body-tertiary" style={{ width: "6%", minWidth: "30px" }}>
                            NO
                        </th>
                        <th className=" text-center text-body-tertiary" style={{ width: "18%", minWidth: "100px" }}>
                            이름
                        </th>
                        <th className=" text-center text-body-tertiary" style={{ width: "18%", minWidth: "100px" }}>
                            아이디
                        </th>
                        <th className=" text-center text-body-tertiary" style={{ width: "18%", minWidth: "100px" }}>
                            연락처
                        </th>
                        <th className=" text-center text-body-tertiary" style={{ width: "18%", minWidth: "100px" }}>
                            비고
                        </th>
                        <th className=" text-center text-body-tertiary" style={{ width: "18%", minWidth: "100px" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {operatorDetail?.data.staff &&
                        operatorDetail.data.staff.map(({ no, name, userId, phone, comments, UUID }) => (
                            <tr key={no}>
                                <td className="text-center">{no}</td>
                                <td className="text-center">{name}</td>
                                <td className="text-center">{userId}</td>
                                <td className="text-center">{phone}</td>
                                <td className="text-center">{comments}</td>
                                <td className="text-center gap-2">
                                    <Button
                                        className="me-3"
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={() => navigate(`/staff-setting/detail?id=${UUID}`)}
                                    >
                                        수정
                                    </Button>
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={(e) => {
                                            handleDelete(e, UUID);
                                        }}
                                    >
                                        삭제
                                    </Button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </div>
    );
};

export default StaffTable;
