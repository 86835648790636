// ProtectedRoute.jsx
import { Navigate } from "react-router-dom";

const useAuth = () => {
    const token = sessionStorage.getItem("token");
    //TODO: 토큰 유효성 확인
    return !!token; // 실제로는 JWT의 유효성을 확인해야 할 수도 있습니다.
};

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useAuth();

    if (!isAuthenticated) {
        // 로그인되어 있지 않다면 로그인 페이지로 리디렉션
        return <Navigate to="/" replace />;
    }

    return children;
};

export default ProtectedRoute;
