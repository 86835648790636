import MuiDatePicker from "components/base/MuiDatePicker";
import { Col, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentDate, currentDuration } from "store/atoms";
import calculateStartDate from "utils/calculateStartDate";
import WeeklySquat from "./WeeklySquat";
import WeeklyStretching from "./WeeklyStretching";
import WeeklyWalking from "./WeeklyWalking";

const WeeklyWorkoutResult = ({ workoutResult }) => {
    const duration = useRecoilValue(currentDuration);
    const [searchDate, setSearchDate] = useRecoilState(currentDate);
    const searchDateStart = calculateStartDate(searchDate, duration);

    const navigate = useNavigate();

    const handleClickDate = (value) => {
        setSearchDate(value);
    };

    return (
        <>
            <Row className="mb-4">
                <Col md={4}>
                    {/* <Form.Control type="date" onChange={handleClickDate} defaultValue={searchDate} /> */}
                    <MuiDatePicker
                        onChange={(value) => {
                            handleClickDate(value);
                        }}
                        labelProp="시작일"
                        value={searchDateStart}
                        readOnlyProp={true}
                    />
                </Col>
                <Col md="auto" className="d-flex align-items-center">
                    ~
                </Col>
                <Col md={4}>
                    {/* <Form.Control type="date" defaultValue={searchDateEnd} disabled /> */}
                    <MuiDatePicker
                        onChange={(value) => {
                            handleClickDate(value);
                        }}
                        value={searchDate}
                        labelProp="종료일"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <WeeklyStretching duration={duration} stretchingResult={workoutResult.stretching} />
                </Col>
                <Col md={12}>
                    <WeeklyWalking duration={duration} walkingResult={workoutResult.walking} />
                </Col>
                <Col md={12}>
                    <WeeklySquat duration={duration} squatResult={workoutResult.squat} />
                </Col>
            </Row>
            <Button className="w-100" variant="primary" onClick={() => navigate("/monitoring", { replace: true })}>
                목록
            </Button>
        </>
    );
};

export default WeeklyWorkoutResult;
