import classNames from "classnames";
import Logo from "components/common/Logo";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AuthSimpleLayout = ({ logo = true, className = "col-xl-5 col-xxl-3", children }) => {
    return (
        <div className="container">
            <Row className="flex-center min-vh-100 py-5">
                <Col sm={10} md={8} lg={5} className={classNames(className)}>
                    {logo && (
                        <Link to="/" className="d-flex flex-center text-decoration-none mb-4">
                            <Logo text={true} width={27} className="fw-bolder fs-5 d-inline-block" />
                        </Link>
                    )}
                    {children}
                </Col>
            </Row>
        </div>
    );
};

export default AuthSimpleLayout;
