import React, { Children, cloneElement, useState } from "react";
import SearchBox from "./SearchBox";
import { Dropdown } from "react-bootstrap";

const DropdownSearchBox = ({ children, className, searchBoxClassName, ...rest }) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    return (
        <Dropdown className={className} onToggle={() => setOpenDropdown(!openDropdown)} show={openDropdown}>
            <Dropdown.Toggle as="div" aria-expanded={openDropdown} bsPrefix="toggle">
                <SearchBox
                    placeholder="Search..."
                    className={searchBoxClassName}
                    value={searchInputValue}
                    onChange={({ target }) => {
                        setSearchInputValue(target.value);
                        setOpenDropdown(true);
                    }}
                    {...rest}
                />
            </Dropdown.Toggle>
            {children && (
                <Dropdown.Menu className="dropdown-menu border font-base start-0 py-0 overflow-hidden w-100" style={{ width: 400 }}>
                    {Children.map(children, (child) =>
                        cloneElement(child, {
                            searchValue: searchInputValue,
                        }),
                    )}
                </Dropdown.Menu>
            )}
        </Dropdown>
    );
};

export default DropdownSearchBox;
