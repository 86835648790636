import React, { createContext, useContext, useState } from "react";

export const MainLayoutContext = createContext({});

const MainLayoutProvider = ({ children }) => {
    const [contentClass, setContentClass] = useState("");
    const [footerClass, setFooterClass] = useState("");
    return <MainLayoutContext.Provider value={{ contentClass, setContentClass, footerClass, setFooterClass }}>{children}</MainLayoutContext.Provider>;
};

export const useMainLayoutContext = () => useContext(MainLayoutContext);

export default MainLayoutProvider;
