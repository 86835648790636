export const OPTION_LIST = [
    { no: 1, title: "오더지시", description: "투약, 검사, 처치, 마취료, 수술료, 약반납" },
    { no: 2, title: "미비 기록 작성", description: "미비기록, 전자서명, 추가기록" },
    { no: 3, title: "예정 환자 조회", description: "수술, 시술, 입원, 외래익일, 검사 예정" },
    { no: 4, title: "진료 및 검사 예약 확인 변경", description: "진료예약, 검사예약" },
    { no: 5, title: "환자 진료 상담", description: "환자의 전화 문의, 타과에서 전화 문의" },
    { no: 6, title: "진단서/의뢰서/사본발급", description: "소견서" },
    { no: 7, title: "진료 지원 업무", description: "영양, 사회사업, 체외순환, 협력센터" },
    { no: 8, title: "약제 업무", description: "복약상담, 약품식별, ACS, TMD, TPN" },
    { no: 9, title: "원무 보험", description: "제증명, 진료비, 민원, 보험" },
    { no: 10, title: "연구", description: "집담회, 임상연구, 위탁연구, 국책연구, 기타" },
    { no: 11, title: "교육", description: "의과대학생 교육 및 실습" },
    { no: 12, title: "응급상황 시 조회", description: "CPR 등" },
];
