import React from "react";
import { Card } from "react-bootstrap";

const SummaryTextbox = ({ value, description, color, startBorder, endBorder, col }) => {
    const startClass = startBorder ? "border-start-xxl border-start" : "";
    const endClass = endBorder ? "border-end-xxl border-end" : "";
    const baseClass = `text-center border-translucent pt-3 pb-xxl-0 col-xxl-${col} col-md-${col} col-${col} ${startClass} ${endClass}`;
    return (
        <div className={baseClass}>
            <h2 className="mb-1 fw-semibold text-center">{value}</h2>
            <h5 className="text-body-tertiary fw-semibold text-center">{description}</h5>
        </div>
    );
};

export default SummaryTextbox;
