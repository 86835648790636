import CustomTooltip from "components/MonitoringDetail/CustomTooltip";
import CustomLegend from "components/MonitoringDetail/CustomLegend";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import formatWeekLabel from "utils/formatWeekLabel";
import FeatherIcon from "feather-icons-react";
import WorkoutSummary_2 from "components/WorkoutResult/WorkoutSummary_2";
import { Card } from "react-bootstrap";

const WeeklyStretching = ({ stretchingResult }) => {
    // Custom Tooltip 컴포넌트
    const CustomStretchingTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const { runningTimeTitle, restingSelfTitle, restingDangerTitle } = payload[0].payload;
            return (
                <CustomTooltip
                    label={label}
                    items={[
                        { color: "#396FDF", text: `운동시간 : ${runningTimeTitle}` },
                        { color: "#11a93d", text: `휴식시간 : ${restingSelfTitle}` },
                        { color: "#e23126", text: `안정시간 : ${restingDangerTitle}` },
                    ]}
                />
            );
        }
        return null;
    };

    const CustomAreaTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const { max, min } = payload[0].payload;
            return (
                <CustomTooltip
                    label={label}
                    items={[
                        { color: "#BE1D95", text: `최대심박수 : ${max}` },
                        { color: "#353963", text: `최소심박수 : ${min}` },
                    ]}
                />
            );
        }
        return null;
    };

    return (
        <div className="mt-5">
            <h1 className="mb-2">스트레칭</h1>
            <Card className="p-4 mb-4">
                <WorkoutSummary_2 hTit="운동현황">
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart width={500} height={300} data={stretchingResult.workout}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tick={{ dx: -30 }} tickFormatter={(value, index) => formatWeekLabel(index)} interval={6} />
                            <YAxis />
                            <Tooltip content={<CustomStretchingTooltip />} />
                            <Legend colors={["#396FDF", "#11A93D", "#E23126"]} verticalAlign="top" align="right" />
                            <Bar dataKey="runningTime" stackId="a" fill="#396FDF" name="운동시간" />
                            <Bar dataKey="restingSelf" stackId="a" fill="#11A93D" name="휴식시간" />
                            <Bar dataKey="restingDanger" stackId="a" fill="#E23126" name="안정시간" />
                        </BarChart>
                    </ResponsiveContainer>
                </WorkoutSummary_2>
                <WorkoutSummary_2 hTit="최소/최대 심박수(bpm)">
                    <ResponsiveContainer height={300}>
                        <AreaChart data={stretchingResult.heartRate}>
                            <defs>
                                <linearGradient id="gradientMax" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="rgba(190,29,149,1)" stopOpacity={1} />
                                    <stop offset="100%" stopColor="rgba(190,29,149,0)" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="gradientMin" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="rgba(53,57,99,1)" stopOpacity={1} />
                                    <stop offset="100%" stopColor="rgba(53,57,99,0)" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="date" tick={{ dx: -30 }} tickFormatter={(value, index) => formatWeekLabel(index)} interval={6} />
                            <YAxis />
                            <Tooltip content={<CustomAreaTooltip />} />
                            <Legend colors={["#BE1D95", "#353963"]} verticalAlign="top" align="right" />
                            <Area type="monotone" dataKey="max" stroke="#BE1D95" strokeWidth={2} fill="url(#gradientMax)" name="최대심박수" />
                            <Area type="monotone" dataKey="min" stroke="#353963" strokeWidth={2} fill="url(#gradientMin)" name="최소심박수" />
                        </AreaChart>
                    </ResponsiveContainer>
                </WorkoutSummary_2>
            </Card>
        </div>
    );
};

export default WeeklyStretching;
