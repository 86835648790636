import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

const Footer = ({ className }) => {
    return (
        <footer className={classNames(className, "footer")}>
            <Row className="g-1 justify-content-between align-items-center h-100">
                <Col xs={12} sm="auto" className="text-center">
                    <p className="mb-0 mt-2 mt-sm-0 text-body">
                        Created by {process.env.REACT_APP_TITLE}
                        <span className="d-none d-sm-inline-block" />
                        <span className="d-none d-sm-inline-block mx-1">|</span>
                        <br className="d-sm-none" />
                        2024 &copy;{" "}
                    </p>
                </Col>
                <Col xs={12} sm="auto" className="text-center">
                    <p className="mb-0 text-body-tertiary text-opacity-85">
                        <FeatherIcon icon="info" size={15} />
                        <span className="ms-2">버전 HCD-DT-WEB v{process.env.REACT_APP_VERSION}</span>
                    </p>
                </Col>
            </Row>
        </footer>
    );
};

export default Footer;
