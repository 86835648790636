import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TrackPreviousPage = () => {
    const location = useLocation();

    useEffect(() => {
        // 현재 경로를 sessionStorage에 저장
        const previousPath = sessionStorage.getItem("currentPath");
        if (previousPath) {
            sessionStorage.setItem("previousPath", previousPath);
        }

        // 현재 경로를 다음 렌더링에서 사용할 수 있도록 저장
        sessionStorage.setItem("currentPath", location.pathname);
    }, [location]);

    return;
};

export default TrackPreviousPage;
