import { Row } from "react-bootstrap";
import OperatorRegisterForm from "./OperatorRegisterForm";

const OperatorRegister = () => {
    return (
        <div className="pb-5">
            <Row className="g-4">
                <div className="mb-2">
                    <h2>운영자 등록</h2>
                </div>
                <hr className="bg-body-secondary mb-3" />
            </Row>
            <div className="order-1 order-xl-0 col-xl-12 col-12">
                <div className="mb-4 shadow-none border overflow-visible card">
                    <div className="p-0 card-body">
                        <div className="p-4">
                            <OperatorRegisterForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OperatorRegister;
