import { INVALID_DATE, INVALID_RANGE } from "constants/errorMessage";
import { toast } from "sonner";

/**
 * 선택한 날짜가 유효한지 확인하는 함수
 *
 * 주어진 날짜 범위가 생년월일 이후이고, 시작일과 종료일의 차이가 0일 이상, 12주(84일) 이하인 경우에만 유효합니다.
 * 조건을 만족하지 않으면 경고 메시지를 출력하고 `false`를 반환합니다.
 *
 * @param {string} searchDateStart - 검사할 시작 날짜 (YYYY-MM-DD 형식)
 * @param {string} searchDateEnd - 검사할 종료 날짜 (YYYY-MM-DD 형식)
 * @param {string} birthDate - 기준이 되는 생년월일 (YYYY-MM-DD 형식)
 * @returns {boolean} 날짜가 유효한 경우 `true`, 그렇지 않은 경우 `false`를 반환
 */
const checkDateValidation = (searchDateStart, searchDateEnd, birthDate) => {
    const startDate = new Date(searchDateStart);
    const endDate = new Date(searchDateEnd);
    const MILLISECONDS_IN_A_DAY = 1000 * 3600 * 24;
    const DAYS_IN_12_WEEKS = 12 * 7; // 12주 = 84일

    // 생년월일보다 이전 날짜인지 확인
    if ((startDate - new Date(birthDate).getTime()) / MILLISECONDS_IN_A_DAY < 0) {
        toast.error(INVALID_DATE);
        return false; // 유효성 검사 실패
    }

    const timeDifference = endDate.getTime() - startDate.getTime();
    const dayDifference = timeDifference / MILLISECONDS_IN_A_DAY;

    // 날짜 범위 유효성 검사
    if (dayDifference < 0 || dayDifference > DAYS_IN_12_WEEKS) {
        toast.error(INVALID_RANGE);
        return false; // 유효성 검사 실패
    }

    return true; // 모든 유효성 검사 통과
};

export default checkDateValidation;
