import CustomTooltip from "components/MonitoringDetail/CustomTooltip";
import CustomLegend from "components/MonitoringDetail/CustomLegend";
import { RPE_VALUE_MAP } from "constants/rpeValueMap";
import { Area, AreaChart, Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import formatWeekLabel from "utils/formatWeekLabel";
import FeatherIcon from "feather-icons-react";
import WorkoutSummary_2 from "components/WorkoutResult/WorkoutSummary_2";
import { Card } from "react-bootstrap";

const WeeklyWalking = ({ walkingResult }) => {
    const CustomWalkingTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const { distance, rpeValue } = payload[0].payload;
            return (
                <CustomTooltip
                    label={label}
                    items={[
                        { color: "#e23126", text: `측정거리 : ${distance}km` },
                        { color: RPE_VALUE_MAP[rpeValue].color, text: rpeValue },
                    ]}
                />
            );
        }
        return null;
    };

    const CustomAreaTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const { targetMax, targetMin, max, min } = payload[0].payload;
            return (
                <CustomTooltip
                    label={label}
                    items={[
                        // { color: "rgba(85, 143, 255, 0.50)", text: `최대목표심박수 : ${targetMax}` },
                        // { color: "rgba(85, 143, 255, 0.50)", text: `최소목표심박수 : ${targetMin}` },
                        { color: "rgba(85, 143, 255, 0.50)", text: `목표심박수 : ${targetMin} ~ ${targetMax}` },
                        { color: "#BE1D95", text: `최대심박수 : ${max}` },
                        { color: "#353963", text: `최소심박수 : ${min}` },
                    ]}
                />
            );
        }
        return null;
    };

    return (
        <div className="mt-5">
            <h1 className="mb-2">걷기</h1>
            <Card className="p-4 mb-4">
                <WorkoutSummary_2 hTit="운동현황">
                    <ResponsiveContainer width="100%" height={300}>
                        <ComposedChart width={500} height={300} data={walkingResult.workout}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tick={{ dx: -30 }} tickFormatter={(value, index) => formatWeekLabel(index)} interval={6} />
                            <YAxis yAxisId="distance" orientation="right" />
                            <YAxis yAxisId="rpeValue" />
                            <Tooltip content={<CustomWalkingTooltip />} />
                            <Legend content={<CustomLegend />} colors={["#E23126", "#D9D9D9"]} verticalAlign="top" align="right" />
                            <Bar yAxisId="rpeValue" dataKey="rpeValue" name="운동자각도">
                                {walkingResult.workout.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={RPE_VALUE_MAP[entry.rpeValue].color} />
                                ))}
                            </Bar>
                            <Line yAxisId="distance" type="linear" dot={false} strokeWidth={4} dataKey="distance" stroke="#E23126" name="측정거리" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </WorkoutSummary_2>

                <WorkoutSummary_2 hTit="최소/최대 심박수(bpm)">
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={walkingResult.heartRate}>
                            <defs>
                                <linearGradient id="gradientMax" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="rgba(190,29,149,1)" stopOpacity={1} />
                                    <stop offset="100%" stopColor="rgba(190,29,149,0)" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="gradientMin" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="rgba(53,57,99,1)" stopOpacity={1} />
                                    <stop offset="100%" stopColor="rgba(53,57,99,0)" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="date" tick={{ dx: -30 }} tickFormatter={(value, index) => formatWeekLabel(index)} interval={6} />
                            <YAxis />
                            <Tooltip content={<CustomAreaTooltip />} />
                            <Legend
                                align="right"
                                verticalAlign="top"
                                payload={[
                                    { value: "최대 심박수", type: "line", color: "#BE1D95" },
                                    { value: "최소 심박수", type: "line", color: "#353963" },
                                    { value: "목표 심박수", type: "line", color: "rgba(85, 143, 255)" },
                                ]}
                                wrapperStyle={{ paddingBottom: "10px", width: "100%", fontSize: "15px" }}
                            />
                            <Area type="monotone" dataKey="max" stroke="#BE1D95" strokeWidth={2} fill="url(#gradientMax)" name="최대심박수" />
                            <Area type="monotone" dataKey="min" stroke="#353963" strokeWidth={2} fill="url(#gradientMin)" name="최소심박수" />
                            <Area
                                type="monotone"
                                dataKey="targetMin"
                                stroke="rgba(85, 143, 255, 0.50)"
                                strokeWidth={4}
                                fill="none"
                                name="최소목표심박수"
                            />
                            <Area
                                type="monotone"
                                dataKey="targetMax"
                                stroke="rgba(85, 143, 255, 0.50)"
                                strokeWidth={4}
                                fill="none"
                                name="최대목표심박수"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </WorkoutSummary_2>
            </Card>
        </div>
    );
};

export default WeeklyWalking;
